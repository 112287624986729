<template>
  <div>
    <Item :list.sync="competitionInProgress" :title="$t('CompetitionInProgress')" />
    <Item :list.sync="competitionEnded" :title="$t('CompetitionEnd')" />
  </div>
</template>

<script>

import Item from './components/Item';
import {getInProgressCompetition} from '../../api/competition';

export default {
  name: 'competition',
  data() {
    return {
      competitionInProgress: [],
      competitionEnded: []
    };
  },
  created() {
    this.getInProgressCompetition();
  },
  methods: {
    getInProgressCompetition() {
      getInProgressCompetition().then((res) => {
        Object.assign(this.$data, res.value || {});
      });
    }
  },
  components: {Item}
};
</script>

<style scoped>

</style>
